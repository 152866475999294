// extracted by mini-css-extract-plugin
export var header = "V_N";
export var item = "V_cg";
export var itemImage = "V_cN";
export var itemIsExternal = "V_cS";
export var itemIsInView = "V_cQ";
export var itemIsLinked = "V_cR";
export var itemText = "V_cP";
export var itemTime = "V_cT";
export var itemTimeIsBig = "V_cV";
export var logo = "V_bM";
export var root = "V_b";